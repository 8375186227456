import styled from 'styled-components';

import { breakpoint } from '@constants/theme';

type Props = {
  spacing: string;
  spacingMd?: string;
  justify?: string;
  align?: 'center' | 'flex-start' | 'flex-end';
  alignMd?: 'center' | 'flex-start' | 'flex-end';
  wrap?: boolean;
};

export const ResponsiveStack = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: ${(_) => _.align ?? 'center'};
  width: 100%;
  height: 100%;
  justify-content: ${(_) => _.justify ?? 'flex-start'};
  flex-wrap: ${(_) => (_.wrap ? 'wrap' : 'nowrap')};

  > * + * {
    margin-top: ${(_) => _.spacing};
  }

  @media (min-width: ${breakpoint.md}px) {
    flex-direction: row;
    align-items: ${(_) => _.alignMd ?? _.align};

    > * {
      margin-top: 0;
    }

    > * + * {
      margin-left: ${(_) => (_.wrap ? '0' : _.spacingMd ?? _.spacing)};
    }

    > * {
      margin-right: ${(_) => (_.wrap ? _.spacingMd ?? _.spacing : '0')};
    }
  }
`;
