import { navigate, useLocation } from '@gatsbyjs/reach-router';
import React, { FC, useState } from 'react';

import { ReactComponent as ArrowIcon } from '@assets/image/svg/arrow.svg';
import { Pagination } from '@components/common';
import { Button } from '@components/controls/button';
import { Block } from '@components/layout/block';
import { Flex } from '@components/layout/flexbox';
import { VStack } from '@components/layout/vstack';
import { Heading2 } from '@components/typographies/heading';
import { Span } from '@components/typographies/span';
import { SimplifiedOrderDetail } from '@features/mypage/History/components/SimplifiedOrderDetail';
import { useDetectMedia } from '@hooks/useDetectMedia';
import useOrders from '@hooks/useOrders';

export const History: FC = () => {
  const location = useLocation();
  const isMyPage = location.pathname === '/mypage' || location.pathname === '/mypage/';
  const [pagination, setPagination] = useState({ page: 1, perPage: isMyPage ? 1 : 10 });
  const { orders, total, current } = useOrders(pagination);
  const { md } = useDetectMedia();

  return (
    <Block width="100%" paddingLeft={md ? '33px' : '0px'} paddingBottom={md ? '0px' : '20px'}>
      <Heading2 fontSize={md ? '24px' : '16px'}>購入履歴</Heading2>
      <Flex gap="30px" alignItems="center" margin="16px 0 0 0" width="100%" direction="column">
        <VStack spacing="16px" align="flex-start" width={'100%'}>
          {isMyPage ? (
            orders[0] === undefined ? null : (
              <VStack spacing="15px" width="100%">
                <SimplifiedOrderDetail
                  key={orders[0].shippingAddress!.item!.orderItemid}
                  id={orders[0].shippingAddress!.item!.orderItemid}
                />
                <Flex jc="flex-end" width="100%">
                  <Button
                    height="35px"
                    width="201px"
                    color="black"
                    bg="gray-second"
                    radius="5px"
                    border="1px solid var(--color-border-gray2)"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      navigate('/mypage/history');
                    }}
                  >
                    <Span fontFamily="Inter" color="black" fontSize="13px" fontWeight="400">
                      購入履歴の一覧を見る <ArrowIcon />
                    </Span>
                  </Button>
                </Flex>
              </VStack>
            )
          ) : (
            orders.map((order) =>
              order.shippingAddress?.item?.orderItemid === undefined ? null : (
                <SimplifiedOrderDetail
                  key={order.shippingAddress.item.orderItemid}
                  id={order.shippingAddress.item.orderItemid}
                />
              )
            )
          )}
        </VStack>
        {!isMyPage && (
          <Pagination
            currentPage={current}
            totalPage={total}
            setCurrentPage={(page) => {
              setPagination((prev) => ({ ...prev, page }));
            }}
          />
        )}
      </Flex>
    </Block>
  );
};
