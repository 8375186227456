import { useQuery } from 'react-query';

import { axiosClient } from '@api/axiosClient';
import { Pagination } from '@pb/api/v1/common/common_pb';
import {
  ListRequest as ListOrderRequest,
  ListRequest,
  ListResponse as ListOrderResponse,
} from '@pb/api/v1/user/order/order_pb';

const getOrders = async (req: ListRequest.AsObject) => {
  const listOrderRequest = new ListOrderRequest();

  if (req?.pagination) {
    const pagination = new Pagination();
    pagination.setPage(req.pagination.page);
    pagination.setPerPage(req.pagination.perPage);
    listOrderRequest.setPagination(pagination);
  }

  const res = await axiosClient.post('/api/v1/user/order/list', listOrderRequest.serializeBinary());
  const listOrderResponse = ListOrderResponse.deserializeBinary(res.data);
  return listOrderResponse.toObject();
};

const useOrders = (pagination: Pagination.AsObject) => {
  const { data: response } = useQuery(['orders', pagination], () => getOrders({ pagination }));

  return {
    orders: response?.orderList || [],
    total: response?.total ? Math.ceil(response.total / pagination.perPage) : 1,
    current: response?.current || 1,
  };
};

export default useOrders;
