import React from 'react';

import Layout from '../../components/layout/layout';

import { History } from '@features/mypage/History';

const HistoryPage = () => (
  <Layout>
    <History />
  </Layout>
);

export default HistoryPage;
