import styled from 'styled-components';

type Props = {
  my?: string;
};

export const Bar = styled.div<Props>`
  width: 100%;
  border-bottom: 1px solid #cfcfcf;
  margin: ${(_) => _.my ?? '20px'} auto;
`;
