import { navigate } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

import { ReactComponent as ArrowIcon } from '../../../../assets/image/svg/arrow.svg';

import { Bar } from '@components/layout/bar';
import { Block } from '@components/layout/block';
import { Card } from '@components/layout/card';
import { Flex } from '@components/layout/flexbox';
import { Grid } from '@components/layout/grid';
import { ResponsiveStack } from '@components/layout/responsive-stack';
import { Anchor } from '@components/typographies/anchor';
import { Paragraph } from '@components/typographies/paragraph';
import { Span } from '@components/typographies/span';
import { FormattedDate } from '@features/mypage/History/components/FormatedDate';
import { OrderItemImage } from '@features/mypage/History/components/OrderItemImage';
import { Price } from '@features/mypage/History/components/Price';
// import { ShippingStatusSteps } from '@features/mypage/History/components/ShippingStatusSteps';
import { useDetectMedia } from '@hooks/useDetectMedia';
import useOrder from '@hooks/useOrder';

export const SimplifiedOrderDetail: FC<{ id: number }> = ({ id }) => {
  const { order } = useOrder(id);
  const { md } = useDetectMedia();

  if (!order) {
    return null;
  }

  return (
    <Flex width="100%" direction="column" gap="16px" alignItems="flex-end">
      <Card
        key={order.orderid}
        px="12px"
        py="20px"
        width="100%"
        border="1.4px solid #d9d9d9"
        radius="5px"
        bg="white"
      >
        <Grid gridTemplateColumns="1fr 170px" md={{ gridTemplateColumns: '130px 1fr' }} gap="20px">
          <OrderItemImage src={order.item?.url} alt={order.item?.name} />
          <Block width="100%" height="100%">
            <Block>
              <ResponsiveStack alignMd="center" align="flex-start" spacing="15px">
                <ShippingInfo
                  md={md}
                  align="flex-start"
                  alignMd="center"
                  wrap
                  spacing="0"
                  spacingMd="1rem"
                >
                  <Flex
                    width="100%"
                    alignItems="flex-start"
                    jc="flex-start"
                    gap={md ? '15px' : '0'}
                    direction={md ? 'row' : 'column'}
                  >
                    {/* {!md && <ShippingStatusSteps status={order.shippingStatus} />} */}
                    <FormattedDate title={'注文日'} dateString={order.purchaseDate} />
                    <Flex width="100%" alignItems="center" jc="flex-start">
                      <Paragraph color="gray-900" lh="24px" width="fit-content">
                        {`お届け先：`}
                        <Span
                          color="var(--color-gray-900)"
                          fontSize="14px"
                          lineHeight="24px"
                          borderBottom="1px solid var(--color-gray-900)"
                        >
                          {order.shippingAddress?.title}
                        </Span>
                      </Paragraph>
                    </Flex>
                  </Flex>
                  <FormattedDate title={'お届け指定日'} dateString={order.expectDate} />
                </ShippingInfo>
                {/* {md && <ShippingStatusSteps status={order.shippingStatus} />} */}
              </ResponsiveStack>
            </Block>
            <Bar my="12px" />
            <Block>
              <Flex direction="column" alignItems="flex-start" gap="8px">
                <Price
                  width="max-content"
                  amount={order.bill?.amount}
                  fontWeight="700"
                  lineHeight="normal"
                />
                <Paragraph lh="normal">{order.item?.name}</Paragraph>
                <Paragraph lh="normal" color="gray-900">
                  {order.shop?.name}
                </Paragraph>
              </Flex>
            </Block>
            <Block
              height="35px"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap="10px"
            >
              <Anchor
                fontSize="12px"
                cursor="pointer"
                onClick={() => navigate(`/mypage/history/item/${id}`)}
              >
                もっと見る
              </Anchor>
              <ArrowIcon />
            </Block>
          </Block>
        </Grid>
      </Card>
    </Flex>
  );
};

const ShippingInfo = styled(ResponsiveStack)<{ md?: boolean }>`
  p {
    width: fit-content;
    white-space: ${(props) => (props.md ? 'nowrap' : 'normal')};
  }
`;
